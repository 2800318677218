import Image from "next/image";
import Link from "next/link";

import HeaderTopData from "../../../data/headerTop";
import { useAppContext } from "@/context/Context";
import { useRouter } from "next/router";

const HeaderTopEight = ({
  bgColor,
  gapSpaceBetween,
  container,
  flexDirection,
  brand,
  announcements,
}) => {
  const router = useRouter();
  const { toggle, setToggle } = useAppContext();
  return (
    <div
      className={`rbt-header-top rbt-header-top-1 ${gapSpaceBetween} ${bgColor} top-expended-activation ${
        !toggle ? "d-none" : ""
      }`}
    >
      <div className={`${container}`}>
        <div className="top-expended-wrapper">
          {HeaderTopData &&
            HeaderTopData.headerTopOne.map((item, index) => (
              <div
                className={`top-expended-inner rbt-header-sec align-items-center ${flexDirection}`}
                key={index}
              >
                <div className="rbt-header-sec-col rbt-header-left d-none d-xl-block">
                  <div className="rbt-header-content">
                    <div className="header-info">
                      <ul className="rbt-information-list">
                        {router.pathname === "/10-online-course" ? (
                          ""
                        ) : (
                          <li>
                            <Link href="#">
                              <i className="feather-phone"></i>
                              {brand.phone}
                            </Link>
                          </li>
                        )}
                      </ul>
                    </div>
                  </div>
                </div>
                <marquee className="rbt-header-sec-col rbt-header-center">
                  <div className="news-text w-100">
                    {announcements?.length > 0
                      ? announcements
                          .map(
                            (item, index) =>
                              `${index + 1} - ${item.displayName}`
                          )
                          .join(". ")
                      : item.introPrice}
                  </div>
                </marquee>
                <div className="rbt-header-sec-col rbt-header-right mt_md--10 mt_sm--10">
                  <div className="rbt-header-content justify-content-start justify-content-lg-end">
                    <div className="header-info">
                      <ul className="rbt-dropdown-menu switcher-language">
                        {item.language.map((lng, innerIndex) => (
                          <li className="has-child-menu" key={innerIndex}>
                            <Link href={lng.link}>
                              <span className="menu-item">
                                {brand.companyName}
                              </span>
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          <div className="header-info">
            <div className="top-bar-expended d-block d-lg-none">
              <button
                className="topbar-expend-button rbt-round-btn"
                onClick={() => setToggle(!toggle)}
              >
                <i className="feather-plus"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderTopEight;
