import React, { useEffect, useState } from "react";
import Link from "next/link";
import Image from "next/image";

import HeaderRightTwo from "../Header-Right/HeaderRight-Two";
import Search from "../Offcanvas/Search";

import logo from "../../../public/images/logo/logo.png";
import logoLight from "../../../public/images/dark/logo/logo-light.png";
import { useAppContext } from "@/context/Context";
import { useAuthContext } from "@/context/auth/AuthContext";

const HeaderEight = ({
  headerType,
  gapSpaceBetween,
  sticky,
  headerSticky,
  navigationEnd,
  container,
  brand,
}) => {
  const [isSticky, setIsSticky] = useState(false);
  const { isLightTheme } = useAppContext();
  const { user } = useAuthContext();
  useEffect(() => {
    const handleScroll = () => {
      const scrolled = window.scrollY;
      if (scrolled > 0) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <>
      <div
        className={`rbt-header-wrapper ${gapSpaceBetween} ${sticky}  ${
          !headerType && isSticky ? `${headerSticky}` : ""
        }`}
      >
        <div className={`${container}`}>
          <div
            className={`mainbar-row ${navigationEnd} align-items-center py-2`}
          >
            <div className="header-left rbt-header-content">
              <div className="header-info">
                <div className="logo position-absolute top-0">
                  {brand?.logo && !isSticky && (
                    <Link href="/student/student-dashboard">
                      {isLightTheme ? (
                        <Image
                          src={brand?.logo}
                          width={152}
                          height={50}
                          priority={true}
                          alt="Education Logo Images"
                        />
                      ) : (
                        <Image
                          src={brand.logo}
                          width={152}
                          height={50}
                          priority={true}
                          alt="Education Logo Images"
                        />
                      )}
                    </Link>
                  )}
                </div>
              </div>

              {/* <div className="header-info d-none d-lg-block">
                <Category />
              </div> */}
            </div>

            {/* <div className="rbt-main-navigation d-none d-xl-block">
              <Nav />
            </div> */}

            {brand && user && (
              <HeaderRightTwo
                userType="Admin"
                btnText="Dashboard"
                btnClass="rbt-marquee-btn marquee-auto btn-border-gradient radius-round btn-sm hover-transform-none"
              />
            )}
          </div>
        </div>
        <Search />
      </div>
    </>
  );
};

export default HeaderEight;
