import Image from "next/image";

import UserData from "../../../data/user.json";
import Link from "next/link";
import { useAuthContext } from "@/context/auth/AuthContext";

const User = () => {
  const { user } = useAuthContext();

  return (
    <div className="rbt-user-menu-list-wrapper">
      <div className="inner" key={user._id}>
        <div className="rbt-admin-profile">
          {user.img && (
            <div className="admin-thumbnail">
              <Image src={user.img} width={43} height={43} alt="User Images" />
            </div>
          )}
          <div className="admin-info">
            <span className="name">{`${user.firstName} ${user.lastName}`}</span>
            <Link
              className="rbt-btn-link color-primary"
              href="/student/student-profile"
            >
              View Profile
            </Link>
          </div>
        </div>
        <ul className="user-list-wrapper">
          {UserData.user[0].userList?.map((list, innerIndex) => (
            <li key={innerIndex}>
              <Link href={list.link}>
                <i className={list.icon}></i>
                <span>{list.text}</span>
              </Link>
            </li>
          ))}
        </ul>

        <hr className="mt--10 mb--10" />
        <ul className="user-list-wrapper">
          <li>
            <Link href="/logout">
              <i className="feather-log-out"></i>
              <span>Logout</span>
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default User;
